import InventoryService from '@/services/inventory.service';
import {mapActions} from 'vuex';

export default {
    name: 'import-raw',
    data() {
        return {
            service: new InventoryService(),
            file: '',
            isLoading: false,
            steps: 'Upload',
            status: ''
        }
    },
    watch: {
        file(newVal) {
            if(newVal === undefined || newVal === null)
                this.file = ''
        }
    },
    methods: {
        ...mapActions([
            'setUploading'
        ]),
        async upload() {
            let formData = new FormData();
            formData.append('file', this.file);
            this.status = 'loading-2';
            this.setUploading(true);
            const res = await this.service.ImportRaw(formData);
            if (res && !res.error) {
                this.$notify({
                    type: 'info',
                    message: this.$t('process_import_start'),
                    duration:4 * 1000,
                });
                this.status = 'completed'
            } else {
                this.status = 'error';
            }
        },
        handleFileUpload(event){
            let reader = event.target.files;
            if (reader.length > 0) {
                this.file = reader[0];
            }
            this.status = 'loading-1';
            this.upload();
        },

        removeFile() {
            this.file = '';
        }
    }
}