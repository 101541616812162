import {mapActions} from 'vuex';
import Dh from '@/helpers/date.helper';
import months from '@/helpers/months';
import {bus} from '@/helpers/bus';

import DataService from '@/services/data.service';

const dataService = new DataService();

export default {
    name: 'ImportCarSales',

    data() {
        return {
            imports: [],
            importModal: false,
            updateModal: false,
            disableImport: true,

            file: '',
            fileExempt: false,
            fileError: '',
            steps: 'Upload',
            status: '',
            errors: {},

            isUpdatingLocations: false,

            totalRows: 0,
            perPage: 25,
            currentPage: 1,
        }
    },

    mounted() {
        this.listImports();
        this.listLocationsImports();
    },

    sockets: {
        ON_IMPORT_SALES_STATS_FINISHED(data) {
            setTimeout(() => {
                const idx = this.imports.findIndex(el => el._id.toString() === data._id.toString());
                if (idx > -1) {
                    this.imports.splice(idx, 1);
                    this.imports.unshift(data);
                }

                this.$notify({
                    type: 'success',
                    message: this.$t('import_success', data)
                });
            }, 1000);
        },

        ON_IMPORT_SALES_LOCATIONS_FINISHED(data) {
            this.isUpdatingLocations = false;

            if (data.Imports > 0) {
                this.$notify({
                    type: 'success',
                    message: this.$t('import_locations_success', data)
                });
            } else {
                this.$notify({
                    type: 'success',
                    message: this.$t('import_locations_no_new')
                });
            }
        },
    },

    methods: {
        toggleImportModal() {
            this.file = '';
            this.fileError = '';
            this.fileExempt = false;
            this.importModal = !this.importModal;
        },

        toggleUpdateModal() {
            this.updateModal = !this.updateModal;
        },

        ...mapActions(['setUploading']),

        async uploadAgain() {
            this.fileError = '';
            this.fileExempt = true;
            this.status = 'loading-1';
            await this.upload();
            this.cleanFile();
        },

        cleanFile() {
            this.importModal = false;
            this.file = '';
            this.fileError = '';
            this.fileExempt = false;
        },

        async upload() {
            const formData = new FormData();
            formData.append('file', this.file);
            formData.append('exemption', this.fileExempt);

            this.disableImport = true;
            this.status = 'loading-2';

            const res = await dataService.importCarSales(formData);
            if (res && !res.error) {
                this.imports.unshift(res.data.stats);
                this.status = 'completed';
                this.toggleImportModal();
            } else {
                this.status = 'error';
                this.fileError = res.errorCode || '';
                this.disableImport = false;
            }
        },

        async updateLocations() {
            this.isUpdatingLocations = true;

            const res = await dataService.importSalesLocations();
            if (res && !res.error) {
                this.toggleUpdateModal();
            }
        },

        async handleFileUpload(event) {
            const reader = event.target.files;
            if (reader.length > 0) this.file = reader[0];

            this.status = 'loading-1';
            await this.upload();
        },

        removeFile() {
            this.file = '';
            this.fileExempt = false;
        },

        listImports(page) {
            this.currentPage = page || 1;
            const skip = this.perPage * (this.currentPage - 1);

            dataService.listImportedCarSalesStats(this.perPage, skip)
                .then(res => {
                    if (res && !res.error) {
                        this.imports = res.data.imports;
                        this.totalRows = res.data.count;
                    }
                });
        },

        listLocationsImports() {
            dataService.listImportedLocations(null, null, {status: 'processing'})
                .then(res => {
                    if (res && !res.error) {
                       this.isUpdatingLocations = res.data.count > 0;
                    }
                });
        },

        next() {
            this.list(this.currentPage + 1);
        },

        prev() {
            this.list(this.currentPage - 1);
        },

        setPerPage(num) {
            bus.$emit('HIDE_ALL_MODALS');
            this.perPage = +(num);
            this.list();
        },
    },

    watch: {
        imports(arr) {
            if (this.currentPage === 1) {
                this.disableImport = arr.filter(el => el.Status === 'processing').length > 0;
            }
        }
    },

    filters: {
        startedAt({startedAt}) {
            return Dh.parseDate(startedAt);
        },

        timeElapsed({startedAt, finishedAt}) {
            let diff = Dh.difference(finishedAt, startedAt);
            if (finishedAt) {
                return Dh.msToTime(diff, 2);
            }
            return '...';
        },

        statsDate({StatsYear, StatsMonth}) {
            return `${months[StatsMonth].name}, ${StatsYear}`;
        },

        localeNumber(number) {
            return number.toLocaleString('en-EN');
        },
    },
}