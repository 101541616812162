import HeaderSelect from '../../../../components/HeaderSelect/index.vue'
import Downloads from '../CarDownloads/index';
import Moment from 'moment';
import {mapActions} from 'vuex';

import InventoryService from '@/services/inventory.service';
import CompanyService from '@/services/company.service.js';


const
    inventoryService = new InventoryService(),
    companyService = new CompanyService();

export default {
    name: 'cars-inventory',
    components: {
        HeaderSelect,
        Downloads
    },
    props: [],
    data() {
        return {
            tabValue: 'Customers',
            company: null,
            companyList: [],
            feedHistory: [],
            companyFeedConfig: null,
            helpDescription: true,
            window: {
                selectCompany: true,
                history: false,
                showDownloads: false,
            },
            steps: 'UploadFile',
            selectType: 'Car',
            file: '',
            list: [],
            nullBodies: [],
            nativeBodies: [],
            dbColumns: {},
            verifyList: [],
        }
    },
    computed: {
        ifValidFeedConfig() {
            return this.companyFeedConfig != null;
        }
    },
    filters: {
        formatDate(date) {
            return Moment(date).format('MM-DD-YYYY hh:mm A');
        }
    },
    async mounted() {
        this.company = this.$getUser().Company._id;
        this.window.selectCompany = false;
        this.window.history = true;
        this.feedConfig();
    },
    methods: {
        ...mapActions([
            'setUploading'
        ]),

        setFile(event) {
            let list = event.target.files;
            if (list.length > 0) {
                this.file = list[0];
            }
        },

        removeFileData() {
            this.file = '';
        },

        setStep(val) {
            if (val === 'UploadFile') {
                this.file = '';
                this.selectType = '';
            }
            if (val === 'DataPreview') {
                this.verifyList = [];
            }
            this.steps = val;
        },

        async upload() {
            let formData = new FormData();
            formData.append('file', this.file);

            this.$showLoading();
            const res = await inventoryService.ReadCarFile(formData);
            this.$hideLoading();

            if (res && !res.error) {
                this.list = res.data.jsonArray !== null ? res.data.jsonArray : [{}];
                this.steps = 'FieldMap';
            }
        },

        async checkNullBodies() {
            if (this.dbColumns.Body === "") {
                this.$notify({
                    type: 'error',
                    message: 'Select filed to match Body Style'
                });
                return;
            }

            this.$showLoading();
            const res = await inventoryService.UnmappedBodies(this.list, this.dbColumns.Body);
            this.$hideLoading();

            if (res && !res.error) {
                this.nullBodies = res.data.map(el => {
                    return {text: el, value: null}
                });
                if (this.nullBodies.length > 0) {
                    this.steps = 'BodyMatch';
                    const sub_res = await inventoryService.NativeBodies();
                    if (sub_res && !sub_res.error) {
                        this.nativeBodies = sub_res.data.map(el => {
                            return {text: el, value: el}
                        });
                    }
                } else
                    await this.verifyData();

            }
        },

        async setNullBodies(index) {

            await inventoryService.SetBody(this.nullBodies[index]);
            if (this.nullBodies.filter(el => el.value === null).length === 0) {
                await this.verifyData()
            }

        },

        getDBColumnsAsArray() {
            return Object.keys(this.dbColumns);
        },

        setDBColumns(fileHeader, $event) {
            this.dbColumns[fileHeader] = $event;
        },

        async getFileHeaders() {
            if (this.companyFeedConfig != null) {
                let result = {};
                const keysFile = Object.keys(this.list[0]);
                const keys = Object.keys(this.companyFeedConfig);
                const values = Object.values(this.companyFeedConfig);
                for (const item of keysFile) {
                    let index = values.indexOf(item);
                    if (index > -1)
                        result[keys[index]] = item;
                    else
                        result[item] = item;
                }
                return result;
            }

            return Object.keys(this.list[0])
        },

        getDBHeaders() {
            return this.verifyList.length > 0 ? Object.keys(this.verifyList[0]) : [];
        },

        async verifyData() {
            this.steps = 'DataPreview';
            const len = this.list.length > 6 ? 6 : this.list.length;
            this.verifyList = [];
            for (let i = 0; i < len; i++) {
                const item = this.list[i];
                let record = {};
                for (let [key, val] of Object.entries(this.dbColumns)) {
                    if (val != null && val !== '') {
                        record[key] = item[val];
                    }
                }
                this.verifyList.push(record);
            }
        },

        ValidHeaders() {
            if (this.dbColumns.VIN.length === 0) {
                return "vin_required";
            } else {
                if (this.dbColumns.Make.length === 0 || this.dbColumns.Model.length === 0 || this.dbColumns.Year.length === 0)
                    return "car_make_model_year";
            }
            return "no_error"
        },

        async Import() {
            const validate = this.ValidHeaders();
            if (validate !== 'no_error')
                this.$notify({
                    group: 'global',
                    type: 'error',
                    message: this.$t(validate),
                });
            else {
                let user = this.$getUser();
                this.setUploading(true);

                this.$showLoading();
                const res = await inventoryService.Import(this.list, this.dbColumns, this.company, user._id, null);
                this.$hideLoading();

                if (res && !res.error) {
                    await companyService.updateFeedConfig(this.dbColumns, this.selectType);
                    this.resetdbColumns();
                    this.file = '';
                    this.steps = "UploadFile";
                    this.window.importData = false;
                    this.window.history = true;
                    this.loadDownloadList();
                    await this.$router.push({name: 'DataImportList', params: {name: 'ImportInventory'}})
                }
                this.setUploading(false);
            }
        },

        resetdbColumns() {
            this.dbColumns = {...this.dbColumnsCopy};
            this.verifyList = [];
        },

        feedConfig() {
            this.resetdbColumns();
            this.file = "";
            this.steps = "UploadFile";
            this.window.importData = true;
            this.window.history = false;
            this.showSelectType = false;
            this.handler(this.selectType);
        },

        getPreviewValue(cad) {

            if (cad && cad.length > 16)
                return cad.substr(0, 16) + '...';
            return cad;

        },

        async handler(el) {
            if (el === 'Car') {
                this.dbColumns = {
                    Location: "",
                    Country: "",
                    DealerId: "",
                    FeedId: "",
                    VIN: "",
                    Stock: "",
                    DecodeString: "",
                    InStock: null,
                    Mileage: null,
                    MileageType: "",
                    Year: null,
                    Make: "",
                    MakeIfOther: "",
                    Model: "",
                    Trim: "",
                    Body: "",
                    Transmission: "",
                    Engine: "",
                    EngineW: "",
                    Cab: "",
                    Inspection: false,
                    DEquipped: false,
                    Title: "",
                    PDisclosure: "",
                    IntColor: null,
                    ExtColor: null,
                    GenericIntColor: null,
                    GenericExtColor: null,
                    Price: null,
                    SpecialPrice: null,
                    RetailPrice: null,
                    DealerCost: null,
                    ModelCode: "",
                    ExtColorCode: "",
                    IntColorCode: "",
                    Certified: "",
                    Condition: "",
                    Type: "",
                    InDate: "",
                    Comments: "",
                    Status: "",
                    Images: "",
                    SelectedImage: "",
                    Options: "",
                    StandardEquipment: "",
                    Fuel: "",
                    DriveType: "",
                    Cylinders: null,
                    OptionCodes: "",
                    MPGCity: "",
                    MPGHighway: "",
                    Doors: "",
                    Drivetrain: "",
                    CabType: "",
                    Slugs: {
                        Stock: "",
                        Google: "",
                        make: "",
                        makeModel: "",
                        yearMakeModel: "",
                        yearMake: ""
                    },
                    extraInfo: {
                        promoOffer: -1,
                        randomAble: true,
                        modifiedPrice: -1,
                        isHold: false
                    },
                    removed: false,
                    removedAt: null,
                    modifiedAt: null,
                    createdAt: new Date(),
                };
            }

            const res = await companyService.getFeedConfig(el);
            if (res && !res.error)
                this.companyFeedConfig = res.data;
        },

        loadDownloadList() {
            this.window.showDownloads = true;
        },

        back() {
            if (this.steps === 'FieldMap') {
                this.file = '';
                this.steps = 'UploadFile';
            }

            if (this.steps === 'DataPreview') {
                this.steps = 'FieldMap';
            }
        }
    }
}
