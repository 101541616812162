import {mapActions} from 'vuex';
import DataService from '@/services/data.service';

const service = new DataService();

export default {
  name: 'import-zip-geojson',
  data() {
    return {
      file: '',
      isLoading: false,
      steps: 'Upload',
      status: ''
    }
  },
  watch: {
    file(newVal) {
      if (newVal === undefined || newVal === null) {
        this.file = ''
      }
    }
  },
  methods: {
    ...mapActions(['setUploading']),

    async upload() {
      const formData = new FormData();
      formData.append('file', this.file);

      this.status = 'loading-2';
      this.setUploading(true);

      const res = await service.ImportGeojsonZipCodes(formData);
      if (res && !res.error) {
        this.$notify({type: 'info', message: this.$t('process_import_start'), duration: 4 * 1000,});
        this.status = 'completed';
      } else {
        this.status = 'error';
      }
    },

    handleFileUpload(event) {
      const reader = event.target.files;
      if (reader.length > 0) {
        this.file = reader[0];
      }

      this.status = 'loading-1';

      this.upload();
    },

    removeFile() {
      this.file = '';
    }
  }
}