import { render, staticRenderFns } from "./List.html?vue&type=template&id=5c386ebd&scoped=true&external"
import script from "./List.js?vue&type=script&lang=js&external"
export * from "./List.js?vue&type=script&lang=js&external"
import style0 from "./List.scss?vue&type=style&index=0&id=5c386ebd&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c386ebd",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2FSettings%2FDataImport%2FList%2Findex.vue&scoped=true&external"
if (typeof block0 === 'function') block0(component)

export default component.exports