import {startCase, lowerCase} from "lodash";
import {mapActions, mapGetters} from 'vuex';

import Dh from "@/helpers/date.helper";
import DownloadImage from '../CarDownloads/index';

import CompanyService from '@/services/company.service.js';
import AgentService from '@/services/agent.service.js';
import CustomerImportService from '@/services/customerImport.service';

const companyService = new CompanyService();
const agentService = new AgentService();
const customerImportService = new CustomerImportService();

export default {
    name: 'list',

    components: {DownloadImage},

    data() {
        return {
            company: null,
            records: [],
            filter: "",

            toAssign: {
                modal: false,
                companyAgent: '',
                companyAgents: [],
                importedAgent: '',
                importedAgents: [],
                isLoading: false
            },
            assigned: [],

            perPage: 10,
            totalRows: 0,
            currentPage: 1,
            searchVal: ''
        }
    },

    sockets: {
        DATA_IMPORT_FINISHED({importData}) {
            const idx = this.records.findIndex(record => record._id.toString() === importData._id.toString());
            if (idx > -1) {
                this.records[idx].Status = importData.Status;
                this.records[idx].Success = importData.Success;
                this.records[idx].Duplicates = importData.Duplicates;
                this.records[idx].Failure = importData.Failure;
            }
        },

        DATA_IMPORT_PROCESSING({importData}) {
            const idx = this.records.findIndex(record => record._id.toString() === importData._id.toString());
            if (idx > -1) {
                this.records[idx].Success = importData.Success;
                this.records[idx].Duplicates = importData.Duplicates;
                this.records[idx].Failure = importData.Failure;
            }
        },

        ASSIGN_IMPORTED_LEAD_DEAL_FINISHED({type, assignedQty}) {
            this.setUploading(false);

            if (type === 'lead') {
                this.$notify({
                    type: 'success',
                    message: this.$t('assign_agents_to_leads_success', {assignedQty})
                });
            } else if (type === 'deal') {
                this.$notify({
                    type: 'success',
                    message: this.$t('assign_agents_to_deals_success', {assignedQty})
                });
            }
        },
    },

    filters: {
        startedAt({createdAt}) {
            return Dh.parseDate(createdAt);
        },

        timeElapsed({createdAt, finishedAt}) {
            let diff = Dh.difference(finishedAt, createdAt);
            if (finishedAt) {
                return Dh.msToTime(diff, 1);
            }
            return '...';
        },

        localeNumber(number) {
            return number.toLocaleString('en-EN');
        },
    },

    computed: {
        ...mapGetters(['getUploading']),

        validAgentCombine() {
            return this.toAssign.companyAgent && this.toAssign.importedAgent;
        },

        validAgentAssign() {
            return this.assigned.length;
        },

        showAgentAssignBtn() {
            return this.filter === 'LEAD' || this.filter === 'DEAL';
        }
    },

    async mounted() {
        switch (this.$route.params.name) {
            case 'ImportInventory':
                this.filter = 'CAR';
                await this.list();
                break;
            case 'ImportCarRaw':
                break;
            case 'ImportSales':
                break;
            case 'ImportDeals':
                this.filter = 'DEAL';
                await this.list();
                break;
            case 'ImportLeads':
                this.filter = 'LEAD';
                await this.list();
                break;
        }

        this.company = this.$getUser().Company._id;
    },

    methods: {
        ...mapActions(['setUploading']),

        async list(page) {
            this.currentPage = page || 1;
            const skip = this.perPage * (this.currentPage - 1);

            const res = await companyService.getFeedHistory(this.$getCompany()._id, this.filter, this.perPage, skip);
            if (res && !res.error) {
                this.records = res.data.record;
                this.totalRows = res.data.count;
            }
        },

        async setPerPage(num) {
            this.perPage = +(num);
            this.list();
        },

        async getCompanies() {

            const res = await companyService.list();
            if (res && !res.error)
                this.companyList = res.data.companies;
        },

        async onExportFeedClick(company) {
            this.company = company;
            const res = await companyService.get(this.company);
            if (res && !res.error) {
                const config = res.data.CompanySettings.Feed.FtpUpload;
                this.schedule.user = config.user;
                this.schedule.password = config.password;
                this.schedule.port = config.port;
                this.schedule.host = config.host;
                this.schedule.name = !config.name || config.name === '' ? `${res.data.Name}-Feed` : config.name;
                this.headers = config.headers;
            }
            this.windows.scheduleModal = true;
        },

        async onFeedClick(company) {

            this.company = company;
            this.exportType = 'car';
            this.windows.previewData = true;
            this.windows.selectCompany = false;
        },

        async onLeadClick(company) {
            this.company = company;
            this.exportType = 'lead';
            this.windows.previewData = true;
            this.windows.selectCompany = false;
        },

        onConfigFeedClick(company) {
            this.$emit('ConfigExportFeedEvent', company);
        },

        next() {
            this.list(this.currentPage + 1);
        },

        prev() {
            this.list(this.currentPage - 1);
        },

        //region Assign agents
        toggleAssign() {
            this.toAssign.modal = !this.toAssign.modal;
            this.toAssign.companyAgent = '';
            this.toAssign.importedAgent = '';
            this.assigned = [];

            if (this.toAssign.modal) {
                this.getAgents();
                this.getImportedAgents();
            }
        },

        addAgents() {
            const importedIdx = this.toAssign.importedAgents.findIndex(el => el.id === this.toAssign.importedAgent),
                agentIdx = this.toAssign.companyAgents.findIndex(el => el.id === this.toAssign.companyAgent);

            this.toAssign.importedAgent = '';
            this.toAssign.companyAgent = '';

            this.assigned.push({
                importedId: this.toAssign.importedAgents[importedIdx].id,
                importedName: this.toAssign.importedAgents[importedIdx].name,
                agentId: this.toAssign.companyAgents[agentIdx].id,
                agentName: this.toAssign.companyAgents[agentIdx].name,
            });

            this.toAssign.importedAgents.splice(importedIdx, 1);
        },

        removeAgents(index) {
            this.toAssign.importedAgents.push({
                id: this.assigned[index].importedId,
                name: this.assigned[index].importedName,
            });

            this.assigned.splice(index, 1);
        },

        async getAgents() {
            const res = await agentService.list();
            if (res && !res.error) {
                this.toAssign.companyAgents = res.data.agents.map(el => ({
                    id: el.User._id, name: `${el.User.FirstName} ${el.User.LastName}`
                }));
            }
        },

        async getImportedAgents() {
            const importType = this.filter.toLowerCase();

            const res = await customerImportService.ImportedAgents(importType);
            if (res && !res.error) {
                this.toAssign.importedAgents = res.data.agents.map(el => ({
                    id: el, name: startCase(lowerCase(el))
                }));
            }
        },

        async assignAgents() {
            const importType = this.filter.toLowerCase();

            const res = await customerImportService.AssignImportedAgents(importType, this.assigned);
            if (res && !res.error) {
                this.toggleAssign();
                this.setUploading(true);
            }
        },
        //endregion
    }
}