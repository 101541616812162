export default [
    {
        "name": "January",
        "abbreviation": "Jan"
    },
    {
        "name": "February",
        "abbreviation": "Feb"
    },
    {
        "name": "March",
        "abbreviation": "Mar"
    },
    {
        "name": "April",
        "abbreviation": "Apr"
    },
    {
        "name": "May",
        "abbreviation": "May"
    },
    {
        "name": "June",
        "abbreviation": "Jun"
    },
    {
        "name": "July",
        "abbreviation": "Jul"
    },
    {
        "name": "August",
        "abbreviation": "Aug"
    },
    {
        "name": "September",
        "abbreviation": "Sept"
    },
    {
        "name": "October",
        "abbreviation": "Oct"
    },
    {
        "name": "November",
        "abbreviation": "Nov"
    },
    {
        "name": "December",
        "abbreviation": "Dec"
    },
];