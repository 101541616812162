import {bus} from "@/helpers/bus";
import {mapGetters, mapActions} from 'vuex';

import InventoryService from "@/services/inventory.service";
const inventoryService = new InventoryService();

export default {
  name: 'index',

  data() {
    return {
      step: 1,
      validateBodies: false,
      validation: {
        Body: false,
        Year: false,
        Make: false,
        Model: false,
        Trim: false
      },
    }
  },

  async mounted() {
    const {feedFileId} = this.$route.params;

    if (feedFileId) {
      await this.setUploadImportFileStep('Match');
      this.step = 2;
    }
  },

  methods: {
    ...mapGetters([
      'getUploadImportFileStep',
      'getUploadImportFileData'
    ]),

    ...mapActions([
      'setUploadImportFileStep',
      'setUploading'
    ]),

    async next() {
      switch (this.step) {
        case 1:
          await this.setUploadImportFileStep('Match');
          this.step = 2;
          await this.$router.push({name: 'ImportCarInventoryMatch'});
          break;

        case 2:
          await this.setUploadImportFileStep('Bodies');
          this.step = 3;
          await this.$router.push({name: 'ImportCarInventoryBodies'});
          break;

        case 3:
          await this.setUploadImportFileStep('Preview');
          this.step = 4;
          await this.$router.push({name: 'ImportCarInventoryReview'});
          break;
      }
    },

    async back() {
      switch (this.step) {
        case 2:
          await this.setUploadImportFileStep('Upload');
          await this.$router.push({name: 'ImportCarInventoryUpload'});
          this.step = 1;
          break;

        case 3:
          await this.setUploadImportFileStep('Match');
          await this.$router.push({name: 'ImportCarInventoryMatch'});
          this.step = 2;
          break;

        case 4:
          if (this.validateBodies) {
            this.validateBodies = false;
            await this.setUploadImportFileStep('Match');
            await this.$router.push({name: 'ImportCarInventoryMatch'});
            this.step = 2;
            break;
          }
          await this.setUploadImportFileStep('Bodies');
          await this.$router.push({name: 'ImportCarInventoryBodies'});
          this.step = 3;
          break;
      }
    },

    async importData() {
      if (this.getUploadImportFileData()) {
        this.$notify({type: 'info', message: this.$t('process_import_start'), duration: 4 * 1000});

        await this.setUploading(true);
        await inventoryService.NewImport(this.getUploadImportFileData(), false, true);

        await this.$router.push('ImportCarInventoryUpload');

      } else {
        this.$notify({type: 'danger', message: this.$t('no_data_import')});
      }
    },

    validateBeforeNext() {
      for (const ele of this.getUploadImportFileData().elements) {
        if (!ele.match) {
          return false;
        } else {
          this.verifyCarValidation(ele.value)
        }
      }
      return true;
    },

    verifyCarValidation(value) {
      if (this.validation[value]) {
        this.validation[value] = true;
      }
    },

    verifyValidation() {
      for (let [e,] of Object.entries(this.validation)) {
        if (!e) {
          return false;
        }
      }
      return true;
    },
  },

  created() {
    bus.$off('passBodyToReview');

    bus.$on('passBodyToReview', async (data) => {
      if (data && !this.validateBodies) {
        this.validateBodies = true;
        await this.next();

      } else {
        this.validateBodies = false;
      }
    });
  },

  beforeDestroy() {
    bus.$off('passBodyToReview');
  },
}