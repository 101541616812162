import {bus} from '@/helpers/bus';
import {mapGetters, mapActions} from 'vuex';

import CustomerImportService from '@/services/customerImport.service';
const customerImportService = new CustomerImportService();

export default {
  name: 'index',

  data() {
    return {
      step: 1,
      importSettings: {
        overrideCustomer: false,
        customerConsent: true,
      },
    }
  },

  async mounted() {
    const {feedFileId} = this.$route.params;

    if (feedFileId) {
      await this.setUploadImportFileStep('Match');
      this.step = 2;
    }

    bus.$off('SET_DEAL_SETTINGS');
    bus.$on('SET_DEAL_SETTINGS', (data) => {
      const {importSettings} = data;
      this.importSettings = importSettings;
    });
  },

  methods: {
    ...mapGetters(['getUploadImportFileStep', 'getUploadImportFileData']),

    ...mapActions(['setUploadImportFileStep', 'setUploading']),

    next() {
      switch (this.step) {
        case 1:
          this.setUploadImportFileStep('Match');
          this.step = 2;
          this.$router.push({name: 'ImportDealMatch'});
          break;
        case 2:
          this.setUploadImportFileStep('Preview');
          this.step = 3;
          this.$router.push({name: 'ImportDealReview'});
          break;
        case 3:

          break;
      }
    },

    back() {
      switch (this.step) {
        case 2:
          this.setUploadImportFileStep('Upload');
          this.$router.push({name: 'ImportDealUpload'});
          this.step = 1;
          break;
        case 3:
          this.setUploadImportFileStep('Match');
          this.$router.push({name: 'ImportDealMatch'});
          this.step = 2;
          break;
      }
    },

    async importData() {
      if (this.getUploadImportFileData()) {

        this.$notify({
          type: 'info',
          message: this.$t('process_import_start'),
          duration: 4 * 1000,
        });

        this.setUploading(true);

        await customerImportService.SaveList(this.getUploadImportFileData(), this.importSettings, 'DEAL');

        await this.$router.push({name: 'DataImportList', params: {name: 'ImportDeals'}});

      } else {
        this.$notify({
          type: 'danger',
          message: this.$t('no_data_import')
        });
      }
    },

    validateBeforeNext() {
      for (const ele of this.getUploadImportFileData().elements) {
        if (!ele.match) {
          return false;
        }
      }
      return true;
    }
  }
}